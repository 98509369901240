import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBCzTKEqZeG9ZppKoMtUqk0fz_9ez7H3xA',
  authDomain: 'sanqua-test-clientapp.firebaseapp.com',
  projectId: 'sanqua-test-clientapp',
  storageBucket: 'sanqua-test-clientapp.appspot.com',
  messagingSenderId: '516379745795',
  appId: '1:516379745795:web:66aec364e74b820656f836',
  measurementId: 'G-3R9KW2VYT3'
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig) // Inisialisasi Firebase dengan firebaseConfig
} else {
  firebase.app() // Jika sudah diinisialisasi, gunakan yang sudah ada
}

const db = firebase.firestore()
export default db
